import React, { useState } from 'react'
import logo from '../../images/logo.svg'
import FILE from './FILE.pdf'
import { MdOutlineClose } from "react-icons/md";
import { CiMenuBurger } from "react-icons/ci";
import { useInView } from "react-intersection-observer";
import { Link as ScrollLink, animateScroll } from 'react-scroll';
import './header.css'



function Headers() {
  const [showNav, setShowNav] = useState(false)
  
  const [isNavActive, setIsNavActive] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
    const downloadResume = () => {
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = FILE;  // Set the href to the imported file path
      link.download = 'JeenResume.pdf';  // Set the file name for download
      link.click();  // Programmatically click the link to trigger the download
    };
  
  
  return (
    <>
    <div className='header-container'>
        <div className="header-left">
          <img src={logo} alt="" />
        </div>
        <div className="header-middle">
        <ScrollLink
          className='navLinks'
          to='about'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => {setShowNav(!showNav)} }
        >
          About
        </ScrollLink>
        <ScrollLink
        className='navLinks'
          to='experiance'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => {setShowNav(!showNav)} }
        >
          Experiance
        </ScrollLink>
        
        <ScrollLink
          to='project'
          className='navLinks'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => {setShowNav(!showNav)} }
        >
          Project
        </ScrollLink>
        <ScrollLink
        className='navLinks'
          to='contact'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => {setShowNav(!showNav)} }
        >
          Contact
        </ScrollLink>
        </div>
        <div className="header-right">
        <button onClick={downloadResume}>Resume</button>
        </div>
        <CiMenuBurger  className={`sub-menu ${isNavActive ? 'active' : 'active'}` } onClick={() => {setShowNav(!showNav)} }/>
    </div>
    <div  className={`responsive-nav ${showNav ? 'active' : ''}`} >
    <MdOutlineClose className='res-close' onClick={() => {setShowNav(!showNav)} }/>
    
       <ScrollLink
          to='about'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => {setShowNav(!showNav)} }
        >
          About
        </ScrollLink>
        <ScrollLink
          to='experiance'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => {setShowNav(!showNav)} }
        >
          Experiance
        </ScrollLink>
        
        <ScrollLink
          to='project'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => {setShowNav(!showNav)} }
        >
          Project
        </ScrollLink>
        <ScrollLink
          to='contact'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => {setShowNav(!showNav)} }
        >
          Contact
        </ScrollLink>
      
        <button onClick={downloadResume}>Resume</button>
        
      
     
     
  </div> 
    </>
  )
}

export default Headers
import React from 'react'
import TST from '../../images/TST.jpg'
import { CiFacebook } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import MOON from '../../images/MOON.jpg'

import './home.css'

function Home() {
  return (
    <div className='home-container'>
        <div className="home-page">
          <h1>Hi, I'm Jeen Maharjan</h1>
          <h3>I am a fullstack web developer</h3>
        </div>
        <div className="about-container" >
          <div className="title-container" id="about" >
            <hr className='line'/>
            <h2>About Me</h2>
            <hr className='line'/>
          </div>
          <div className="about-paragraph">
            <p>Hey, I’m Jeen Maharjan. I have a strong interest in IT and hold a bachelor’s degree in computer engineering. My experience in web development includes creating and launching websites tailored to meet client needs. I focus on clear communication and adapting to feedback, ensuring each project meets both technical standards and client expectations.</p>
            <p>Working as a freelancer, I completed projects that met client needs and left them satisfied. I make each stage of development a team effort, combining technical skills with a focus on client goals to create functional, user-friendly results.</p>
          </div>
         
        </div>
        <div className="experiance-container">
          <div className="title-container" id="experiance">
            <hr className='line'/>
            <h2>Experiance</h2>
            <hr className='line'/>
          </div>
          <div className="experiance-div">
              <div className="experiance-item">
                <div className="experiance-item-left">
                  <div className="experiance-item-top">
                  <h2>Project Manager</h2>
                  <h3>TST Real Estates</h3>
                  </div>
                  
                  <hr className='experiance-l-line'/>
                  <h4>March 2024 - Dec 2024</h4>
                </div>
                <hr className='experiance-line'/>
                <div className="experiance-item-right">
                  <ul>
                    <li>
                    Planned, built, and managed the TST Real Estate website, handling both backend API development and frontend design to create a seamless user experience.
                    </li>
                    <li>
                    Provided advice on marketing and promotional approaches, helping to improve brand visibility and outreach.
                    </li>
                    <li>
                    Worked closely with employees to understand their needs, implementing features and updates that aligned with team goals.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="experiance-item">
                <div className="experiance-item-left">
                  <div className="experiance-item-top">
                  <h2>Web Developer</h2>
                  <h3>Moonkiri Design Studio</h3>
                  </div>
                  
                  <hr className='experiance-l-line'/>
                  <h4>Jun 2023 -  Feb 2024</h4>
                </div>
                <hr className='experiance-line'/>
                <div className="experiance-item-right">
                  <ul>
                    <li>
                    Created a digital portfolio that highlighted various design projects, allowing potential clients to explore the studio’s work.
                    </li>
                    <li>
                    Collaborated with the team to understand their vision and requirements, tailoring the website to reflect their brand and style.
                    </li>
                    
                  </ul>
                </div>
              </div>
          </div>
        </div>
        <div className="projects-container">
          <div className="title-container" id="project">
            <hr className='line'/>
              <h2>Projects</h2>
            <hr className='line'/>
          </div>
          <div className="project-items">
            <div className="each-project">
              <div className="image-div">
                <img src={TST} alt="" />
              </div>
              <div className="each-project-left">
                <h2>TST Real Estate</h2>
                <p>A dynamic and interactive website showcasing a full range of real estate services. 
                  This site highlights all available properties, featuring detailed information on each, including location 
                  on a dynamic map, high-quality images, and descriptive content, along with video walkthroughs for clearer 
                  understanding. Additionally, it includes sections for team contacts and other essential project information, 
                  creating an engaging and informative experience for prospective buyers.</p>
                  
                  <a href="https://tstrealestates.com/" target='_blank'><button>Visit Page</button></a>
                <hr />
                <div className="project-tools">
                  <p>HTML</p>
                  <p>CSS</p>
                  <p>React</p>
                  <p>Node</p>
                  <p>MongoDB</p>
                  <p>AWS</p>
                </div>
                </div>

            </div>

            <div className="each-project two">
              <div className="image-div">
                <img src={MOON} alt="" />
              </div>
              <div className="each-project-left">
                <h2>MoonKiri Design Studio</h2>
                <p>A informative static page designed for Moonkiri Design Studio. This site showcases essential 
                  company information, including available services, contact details, and highlights their portfolio of 
                  design and construction projects. With a focus on visual appeal, it provides visitors with a clear understanding 
                  of the studio’s expertise and project achievements.</p>
                  
                  <a href="https://moonkiridesign.com.np/" target='_blank'><button>Visit Page</button></a>
                <hr />
                <div className="project-tools">
                  <p>HTML</p>
                  <p>CSS</p>
                  <p>React</p>

                </div>
                </div>

            </div>
          </div>
        </div>
        <div className="contact-container">
          <div className="title-container" id="contact">
            <hr className='line'/>
              <h2>Contact</h2>
            <hr className='line'/>
          </div>
          <div className="contact-message">
            <p>Passionate about development? Have a unique project idea and think I might be the right fit to bring it to life? 
              Don’t hesitate to reach out! My inbox is always open—whether you’re a potential client, recruiter, 
              or just want to chat about all things tech. Looking forward to hearing from you!</p>
          </div>
          <button> <a href="mailto:jeenmaharjan08@gmail.com">Write Message</a>  </button>
          <div className="contact-social-icons">
              <a href="https://www.facebook.com/shadowfiend6" target='_blank'><CiFacebook className='contact-social'></CiFacebook></a>
              <a href="https://www.instagram.com/jeen_mhrzn/" target='_blank'><FaInstagram className='contact-social'/></a>
              <a href="https://www.linkedin.com/in/jeen-maharjan-905a97255/" target='_blank'><FaLinkedin className='contact-social'/></a>
          </div>
        </div>
    </div>
  )
}

export default Home
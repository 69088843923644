import { BrowserRouter as Router, Route, Switch , useLocation  } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./component/Home/Home.jsx";

import PageNotfound from "./component/PageNotfound/PageNotfound.jsx"


import Headers from "./component/Headers/Headers.jsx"
import { ReactLenis } from '@studio-freight/react-lenis';



import { useEffect, useState } from "react";


function Layout() {

  
  const lenisOptions = {
    lerp: 0.07, // Increase this value for smoother and slower scrolling
    smooth: true,
    direction: 'vertical'
  };

  return (
    <ReactLenis root={true} autoRaf={true} options={lenisOptions}>
       
     <Headers />
      <Switch>
        <Route exact path="/" component={Home} />
        
        <Route component={PageNotfound} />
      </Switch>
      

    </ReactLenis>
  );
}

function App() {
  const [loadingg, setloadingg] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setloadingg(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <ToastContainer position="top-center" />
     <Layout />
    </Router>
  );
}

export default App;
